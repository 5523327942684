import "core-js";
import "url-search-params-polyfill";
import "./src/styles/reset.css";
import "./src/styles/global.css";

const scrollTo = id => () => {
  const el = document.querySelector(id);
  if (el) {
    const headerEl = document.querySelector("header.header");
    return window.scrollTo(0, el.offsetTop - headerEl.clientHeight);
  }
  return false;
};

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash));
  }
};
